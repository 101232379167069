import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const pickerAndPacker = '../../../assets/img/pickerAndPacker.svg';
const dispatch = '../../../assets/img/dispatch.svg';
const dataEntry = '../../../assets/img/dataEntry.svg';
const eventCrew = '../../../assets/img/eventCrew.svg';
const partTimer = '../../../assets/img/partTimer.svg';
const food = '../../../assets/img/food.svg';
const petCare = '../../../assets/img/petCare.svg';

const homeData = [
  {
    id: 1,
    avatar: (
      <StaticImage
        src={pickerAndPacker}
        placeholder="blurred"
        alt="Pack / Move"
      />
    ),
    title: 'Pack / Move',
    description: 'Help me move in or out, arrange furniture, pack and unpack.',
    url: `${process.env.GATSBY_PWA_PATH}/personal_helper?task=pick_and_move`
  },
  {
    id: 2,
    avatar: (
      <StaticImage
        src={partTimer}
        placeholder="blurred"
        alt="Household Chores"
      />
    ),
    title: 'Household Chores',
    description: 'Help me with home repairs, gardening and cooking.',
    url: `${process.env.GATSBY_PWA_PATH}/personal_helper?task=household_chores`
  },
  {
    id: 3,
    avatar: <StaticImage src={petCare} placeholder="blurred" alt="Pet care" />,
    title: 'Pet Care',
    description: 'Help me walk my dog, feed my pet and vet visits.',
    url: `${process.env.GATSBY_PWA_PATH}/personal_helper?task=pet_care`
  }
];

const lifeData = [
  {
    id: 1,
    avatar: <StaticImage src={dispatch} placeholder="blurred" alt="Dispatch" />,
    title: 'Dispatch',
    description: 'Help me deliver almost anything.',
    url: `${process.env.GATSBY_PWA_PATH}/dispatch`
  },
  {
    id: 2,
    avatar: (
      <StaticImage src={food} placeholder="blurred" alt="Food / Shopping" />
    ),
    title: 'Food / Shopping',
    description: 'Help me buy, pay and deliver.',
    url: `${process.env.GATSBY_PWA_PATH}/food_shopping`
  },
  {
    id: 3,
    avatar: <StaticImage src={eventCrew} placeholder="blurred" alt="Plan" />,
    title: 'Plan',
    description: 'Help me plan birthdays, events and surprises.',
    url: `${process.env.GATSBY_PWA_PATH}/personal_helper?task=plan`
  },
  {
    id: 4,
    avatar: (
      <StaticImage
        src={dataEntry}
        placeholder="blurred"
        alt="Personal assistant"
      />
    ),
    title: 'Personal Assistant',
    description:
      'Help me with stamping, research, data entry and banking cheques.',
    url: `${process.env.GATSBY_PWA_PATH}/personal_helper?task=personal_assistant`
  }
];

export { homeData, lifeData };
