import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import eliteGG from '../../../assets/img/eliteGoGetter.png';
import foodDeliveryExpert from '../../../assets/img/foodDeliveryExpert.png';
import skilledInEnglish from '../../../assets/img/skilledInEnglish.png';
import PersonalShopper from '../../../assets/img/PersonalShopper.png';

const movingBulky = '../../../assets/img/movingBulky.svg';
const chequeDeposit = '../../../assets/img/chequeDeposit.svg';
const dataEntry = '../../../assets/img/dataEntry.svg';
const eventCrew = '../../../assets/img/eventCrew.svg';
const food = '../../../assets/img/food.svg';
const dispatch = '../../../assets/img/dispatch.svg';

const Hanis = '../meetOurCommunity/assets/Hanis.png';
const Adrian = '../meetOurCommunity/assets/Adrian.png';
const LauHuiChin = '../meetOurCommunity/assets/LauHuiChin.png';
const Pavalan = '../meetOurCommunity/assets/Pavalan.png';

const meetCommunityData = [
  {
    photo: (
      <StaticImage
        src={Pavalan}
        placeholder="blurred"
        alt="Pavalan A/L Murugan"
      />
    ),
    name: 'Pavalan A/L Murugan',
    since: 2016,
    rating: '4.94',
    stats: [
      {
        head: '23',
        text: 'Favourited by'
      },
      {
        head: '1,841',
        text: 'Jobs completed'
      }
    ],
    badges: [
      {
        image: eliteGG,
        name: 'Elite GoGetter'
      },
      {
        image: skilledInEnglish,
        name: 'Skilled in English'
      }
    ],
    experience: [
      {
        image: (
          <StaticImage
            src={chequeDeposit}
            placeholder="blurred"
            style={{ width: '100%' }}
            alt="Cheque Deposit"
          />
        ),
        name: 'Cheque Deposit',
        link: `${process.env.GATSBY_PWA_PATH}/cheque_deposit`
      },
      {
        image: (
          <StaticImage
            src={food}
            placeholder="blurred"
            style={{ width: '100%' }}
            alt="Food & Shopping"
          />
        ),
        name: 'Food / Shopping',
        link: `${process.env.GATSBY_PWA_PATH}/food_shopping`
      },
      {
        image: (
          <StaticImage
            src={dispatch}
            placeholder="blurred"
            style={{ width: '100%' }}
            alt="Cheque Deposit"
          />
        ),
        name: 'Dispatch',
        link: `${process.env.GATSBY_PWA_PATH}/dispatch`
      }
    ]
  },
  {
    photo: (
      <StaticImage src={LauHuiChin} placeholder="blurred" alt="Lau Hui Chin" />
    ),
    name: 'Lau Hui Chin',
    since: 2020,
    rating: '4.97',
    stats: [
      {
        head: '22',
        text: 'Favourited by'
      },
      {
        head: '230',
        text: 'Jobs completed'
      }
    ],
    badges: [
      {
        image: PersonalShopper,
        name: 'Personal Shopper'
      },
      {
        image: foodDeliveryExpert,
        name: 'Food Delivery Expert'
      }
    ],
    experience: [
      {
        image: (
          <StaticImage
            src={food}
            placeholder="blurred"
            style={{ width: '100%' }}
            alt="Food & Shopping"
          />
        ),
        name: 'Food / Shopping',
        link: `${process.env.GATSBY_PWA_PATH}/food_shopping`
      },
      {
        image: (
          <StaticImage
            src={chequeDeposit}
            placeholder="blurred"
            style={{ width: '100%' }}
            alt="Cheque Deposit"
          />
        ),
        name: 'Cheque Deposit',
        link: `${process.env.GATSBY_PWA_PATH}/cheque_deposit`
      },
      {
        image: (
          <StaticImage
            src={movingBulky}
            placeholder="blurred"
            style={{ width: '100%' }}
            alt="Moving Bulky Items"
          />
        ),
        name: 'Moving Bulky Items',
        link: `${process.env.GATSBY_PWA_PATH}/part_timer?task=bulky`
      },
      {
        image: (
          <StaticImage
            src={dispatch}
            placeholder="blurred"
            style={{ width: '100%' }}
            alt="Dispatch"
          />
        ),
        name: 'Dispatch',
        link: `${process.env.GATSBY_PWA_PATH}/dispatch`
      }
    ]
  },
  {
    photo: <StaticImage src={Hanis} placeholder="blurred" alt="Hanis Hassan" />,
    name: 'Hanis Hassan',
    since: 2018,
    rating: '4.98',
    stats: [
      {
        head: '9',
        text: 'Favourited by'
      },
      {
        head: '364',
        text: 'Jobs completed'
      }
    ],
    badges: [
      {
        image: eliteGG,
        name: 'Elite GoGetter'
      }
    ],
    experience: [
      {
        image: (
          <StaticImage
            src={food}
            placeholder="blurred"
            style={{ width: '100%' }}
            alt="Food & Shopping"
          />
        ),
        name: 'Food / Shopping',
        link: `${process.env.GATSBY_PWA_PATH}/food_shopping`
      },
      {
        image: (
          <StaticImage
            src={dataEntry}
            placeholder="blurred"
            style={{ width: '100%' }}
            alt="Data Entry"
          />
        ),
        name: 'Personal Assistant',
        link: `${process.env.GATSBY_PWA_PATH}/personal_helper?task=personal_assistant`
      },
      {
        image: (
          <StaticImage
            src={eventCrew}
            placeholder="blurred"
            style={{ width: '100%' }}
            alt="Event Crew"
          />
        ),
        name: 'Event Staff',
        link: `${process.env.GATSBY_PWA_PATH}/part_timer?task=event_crew`
      },
      {
        image: (
          <StaticImage
            src={dispatch}
            placeholder="blurred"
            style={{ width: '100%' }}
            alt="Dispatch"
          />
        ),
        name: 'Dispatch',
        link: `${process.env.GATSBY_PWA_PATH}/dispatch`
      }
    ]
  },
  {
    photo: <StaticImage src={Adrian} placeholder="blurred" alt="Adrian Quah" />,
    name: 'Adrian Quah',
    since: 2015,
    rating: '4.93',
    stats: [
      {
        head: '54',
        text: 'Favourited by'
      },
      {
        head: '1,189',
        text: 'Jobs completed'
      }
    ],
    badges: [
      {
        image: eliteGG,
        name: 'Elite GoGetter'
      },
      {
        image: foodDeliveryExpert,
        name: 'Food Delivery Expert'
      }
    ],
    experience: [
      {
        image: (
          <StaticImage
            src={food}
            placeholder="blurred"
            style={{ width: '100%' }}
            alt="Food & Shopping"
          />
        ),
        name: 'Food / Shopping',
        link: `${process.env.GATSBY_PWA_PATH}/food_shopping`
      },
      {
        image: (
          <StaticImage
            src={chequeDeposit}
            placeholder="blurred"
            style={{ width: '100%' }}
            alt="Cheque Deposit"
          />
        ),
        name: 'Cheque Deposit',
        link: `${process.env.GATSBY_PWA_PATH}/cheque_deposit`
      },
      {
        image: (
          <StaticImage
            src={dataEntry}
            placeholder="blurred"
            style={{ width: '100%' }}
            alt="Data Entry"
          />
        ),
        name: 'Personal Assistant',
        link: `${process.env.GATSBY_PWA_PATH}/personal_helper?task=personal_assistant`
      },
      {
        image: (
          <StaticImage
            src={dispatch}
            placeholder="blurred"
            style={{ width: '100%' }}
            alt="Dispatch"
          />
        ),
        name: 'Dispatch',
        link: `${process.env.GATSBY_PWA_PATH}/dispatch`
      }
    ]
  }
];

export { meetCommunityData };
