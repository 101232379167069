import React from 'react';

import styled from 'styled-components/macro';

import { Button } from '@material-ui/core';

import { KeyboardArrowRight } from '@material-ui/icons';

import colors from '../assets/colors';
import fontSize from '../assets/fontSize';

const TabButton = styled(Button)`
  &.button {
    width: 100%;
    flex: 1;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${colors.white};
    text-transform: none;
    border-radius: 0.5rem;
    border: 1px solid ${colors.grey};
    margin-bottom: 8px;
    font-family: 'Open Sans', sans-serif !important;
  }
`;
const ButtonTitle = styled.div`
  font-size: ${fontSize.xLarge};
  font-family: 'Open Sans', sans-serif !important;
  font-style: normal;
  font-weight: bold;
  line-height: 22px;
`;
const ButtonDescription = styled.div`
  font-size: ${fontSize.large};
  font-weight: 300;
  line-height: 19px;
  font-weight: normal;
  font-family: 'Open Sans', sans-serif !important;
`;
const Link = styled.a`
  all: unset;
`;
const StyledAvatar = styled.div`
  width: 60px;
  height: 60px;
  margin-right: 8px;
`;
const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const InformativeButton = props => {
  const { title, description, url, avatar } = props;
  return (
    <Link href={url}>
      <TabButton className="button">
        <Wrapper>
          <StyledAvatar>{avatar}</StyledAvatar>
          <TitleWrapper>
            <ButtonTitle>{title}</ButtonTitle>
            <ButtonDescription>{description}</ButtonDescription>
          </TitleWrapper>
        </Wrapper>
        <KeyboardArrowRight />
      </TabButton>
    </Link>
  );
};

export default InformativeButton;
