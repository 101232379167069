import React from 'react';
import styled from 'styled-components/macro';
import TitleHeaderText from '../../../components/TitleHeaderText';
import { howItWorksData } from '../const/howItworksData';

import HowItWorks from '../../../views/shared/HowItWorksV2';

const MainDiv = styled.div`
  margin-top: -50px;
`;

function HowitWorks(props) {
  const { marginExtra } = props;
  return (
    <MainDiv>
      <TitleHeaderText marginExtra={marginExtra} text="How it works" />
      <HowItWorks className="home" data={howItWorksData} />
    </MainDiv>
  );
}

export default HowitWorks;
