import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import styled, { css } from 'styled-components/macro';

import _ from 'lodash';

import { Home, Favorite } from '@material-ui/icons';

import HowToHelpCard from './HowToHelpCard';

import InformativeButton from '../../../components/InformativeButton';

import { homeData, lifeData } from '../const/informativeButtonDate';

import colors from '../../../assets/colors';

const CleaningWindow = './assets/cleaningWindow.jpg';
const ShoppingGirl = './assets/shoppingGirl.jpg';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-around;
  @media (max-width: 650px) {
    flex-direction: column;
    align-items: center;
  }
`;
const TitleHeader = styled.h1`
  text-align: center;
  font-weight: bold;
  font-size: 36px;
  line-height: 49px;
  margin-bottom: 3rem;
  @media (max-width: 768px) {
    margin-top: 7rem;
  }
`;

const ShareStyleIcon = css`
  color: ${colors.white} !important;
  font-size: 19px !important;
`;
const HomeIcons = styled(Home)`
  ${ShareStyleIcon};
`;
const FavoriteIcon = styled(Favorite)`
  ${ShareStyleIcon};
`;
const Container = styled.div`
  margin: auto;
  max-width: 1155px;
  box-sizing: border-box;
  z-index: -1;
  padding-left: 1rem;
  padding-right: 1rem;
`;
const WrapperDropDown = styled.div`
  margin-top: 1rem;
  margin-bottom: 2rem;
  margin-right: 1rem;
`;

const HowToHelp = () => {
  return (
    <Container>
      <TitleHeader>Here’s how we can help</TitleHeader>
      <Wrapper>
        <HowToHelpCard
          margin
          image={
            <StaticImage
              src={CleaningWindow}
              placeholder="blurred"
              style={{ height: '100%' }}
              alt="Girl cleaning window"
            />
          }
          icon={<HomeIcons />}
          description={
            'Hire reliable help for packing, do household chores and pet care.'
          }
          title={'Home'}
          buttonText={'SEE ALL SERVICES'}
          collapseModalChildren={
            <WrapperDropDown>
              {_.map(homeData, data => (
                <InformativeButton
                  key={data.id}
                  title={data.title}
                  description={data.description}
                  url={data.url}
                  avatar={data.avatar}
                />
              ))}
            </WrapperDropDown>
          }
        />
        <HowToHelpCard
          image={
            <StaticImage
              src={ShoppingGirl}
              placeholder="blurred"
              style={{ height: '100%' }}
              alt="Girl buying shopping"
            />
          }
          icon={<FavoriteIcon />}
          description={
            'Get trusted help to dispatch, shop, plan and provide work assistance.'
          }
          title={'Life'}
          buttonText={'SEE ALL SERVICES'}
          collapseModalChildren={
            <WrapperDropDown>
              {_.map(lifeData, data => (
                <InformativeButton
                  key={data.id}
                  title={data.title}
                  description={data.description}
                  url={data.url}
                  avatar={data.avatar}
                />
              ))}
            </WrapperDropDown>
          }
        />
      </Wrapper>
    </Container>
  );
};

export default HowToHelp;
