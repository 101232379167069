import React, { useState } from 'react';

import styled from 'styled-components/macro';

import { Paper, Avatar, IconButton } from '@material-ui/core';

import { KeyboardArrowRight, Close } from '@material-ui/icons';

import CollapseModal from '../../../views/shared/CollapseModal';

import colors from '../../../assets/colors';
import fontSize from '../../../assets/fontSize';

const Container = styled.div`
  display: flex;
  flex: 1;
  width: 542px;
  @media (max-width: 1225px) {
    flex-direction: column;
    width: unset;
  }
`;
const StyledPaper = styled(Paper)`
  &.paper {
    margin-right: ${({ margin }) => margin === 'true' && '3rem'};
    border-radius: 20px;
    box-shadow: 0px 4px 6px rgba(206, 206, 206, 0.5);
    overflow: hidden;
    height: 100%;
    @media (max-width: 1225px) {
      margin-right: 1rem;
      margin-bottom: 1rem;
      width: 300px;
    }
  }
`;
const TitleDropDown = styled.h3`
  margin-left: 8px;
  margin-right: 8px;
  margin-top: 0;
  margin-bottom: 0;
  font-size: ${fontSize.xLarge}; ;
`;
const Description = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: ${fontSize.large};
  line-height: 19px;
`;
const KeyboardArrowRightIcon = styled(KeyboardArrowRight)`
  color: ${colors.primary} !important;
  margin-left: 1rem;
`;
const StyledButton = styled.div`
  color: ${colors.primary};
  display: flex;
  cursor: pointer;
  font-size: ${fontSize.large};
  align-items: center;
`;

const LeftWrapper = styled.div`
  width: 224px;
  height: 163px;
  @media (max-width: 1225px) {
    width: 300px;
    height: 200px;
  }
`;
const RightWrapper = styled.div`
  flex: 1;
  padding: 1rem 2rem;
`;
const DropdownTitleContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: ${fontSize.large};
  font-weight: 800;
`;
const StyledAvatar = styled(Avatar)`
  background: ${colors.primary} !important;
  width: 2rem !important;
  height: 2rem !important;
`;

const ContainerModal = styled.div`
  padding: 1rem;
  padding-right: 0;
`;
const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;
const FlexHeader = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;
const H4 = styled.h4`
  margin: 0;
  padding-left: 8px;
`;

const HowToHelpCard = props => {
  const {
    image,
    icon,
    description,
    title,
    buttonText,
    collapseModalChildren,
    margin
  } = props;
  const [openHomeItems, handleOpenHomeItems] = useState(false);
  return (
    <StyledPaper className="paper" margin={margin ? 'true' : undefined}>
      <Container>
        <LeftWrapper>{image}</LeftWrapper>
        <RightWrapper>
          <DropdownTitleContainer>
            <StyledAvatar>{icon}</StyledAvatar>
            <TitleDropDown>{title}</TitleDropDown>
          </DropdownTitleContainer>
          <Description>{description}</Description>
          <StyledButton onClick={() => handleOpenHomeItems(!openHomeItems)}>
            {buttonText}
            <KeyboardArrowRightIcon />
          </StyledButton>
        </RightWrapper>
      </Container>
      <CollapseModal
        open={openHomeItems}
        handleClose={() => handleOpenHomeItems(false)}
      >
        <ContainerModal>
          <FlexHeader>
            <HeaderLeft>
              <StyledAvatar>{icon}</StyledAvatar>
              <H4>{title}</H4>
            </HeaderLeft>
            <IconButton onClick={() => handleOpenHomeItems(false)}>
              <Close />
            </IconButton>
          </FlexHeader>
          {collapseModalChildren}
        </ContainerModal>
      </CollapseModal>
    </StyledPaper>
  );
};

export default HowToHelpCard;
