import React, { useEffect } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../components/Layout';

import styled, { css } from 'styled-components/macro';

import TopHeader from '../views/shared/TopHeaderV2';
import AdvantagesOfGoget from '../views/shared/AdvantagesOfGogetV2';

import HowToHelp from '../components/homeAndLife/howToHelp/HowToHelp';
import JobsProgress from '../components/homeAndLife/jobsInProgress/JobsProgress';

import HowItWorks from '../components/homeAndLife/howItWorks/HowitWorks';

import HeaderSeo from '../components/HeaderSeo';

import MeetOurCommunity from '../views/BusinessPage/MeetOurCommunity';

import FAQSection from '../views/shared/FAQ';
import DownloadUs from '../views/shared/DownloadUs';
import Banner from '../views/shared/Banner';
import { FaqData } from '../components/homeAndLife/const/FaqData';
import { meetCommunityData } from '../components/homeAndLife/const/meetCommunityData';

import colors from '../assets/colors';

import MobileFooterBackground from '../assets/img/homeAndLife/MobileFooterBackground.png';
import MobileHeaderBackground from '../assets/img/homeAndLife/MobileHeaderBackground.png';
import DesktopHereHowWeCanHelpBackground from '../assets/img/homeAndLife/DesktopHereHowWeCanHelpBackground.png';
import DesktopbackgroundHome from '../assets/img/DesktopbackgroundHome.png';

import MetaImg from '../assets/img/meta/homelife.png';
import { graphql } from 'gatsby';

const DesktopBannerImage = '../assets/img/homeAndLife/banner-desktop.jpg';
const MobileBannerImage = '../assets/img/homeAndLife/banner-mobile.jpg';
const HelpAndLifeBanner = '../assets/img/homeAndLife/helpAndLifeBanner.jpg';
const MobileHelpAndLifeBanner =
  '../assets/img/homeAndLife/mobile-help-life-banner.jpg';

const ConsistentlyReliable =
  '../assets/img/homeAndLife/advantageOfGoGet/ConsistentlyReliable.png';
const PerfectMatch =
  '../assets/img/homeAndLife/advantageOfGoGet/PerfectMatch.png';
const SafeSecure = '../assets/img/homeAndLife/advantageOfGoGet/SafeSecure.png';
const TrainedVerified =
  '../assets/img/homeAndLife/advantageOfGoGet/TrainedVerified.png';

const Container = styled.div``;
const SharedBackground = css`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;
const Wrapper = styled.div`
  background-image: url(${DesktopHereHowWeCanHelpBackground});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  padding-bottom: 9rem;
  padding-top: 3rem;
  @media (max-width: 768px) {
    ${SharedBackground};
    background-image: url(${MobileHeaderBackground});
    padding-top: 1px;
    margin-top: 2rem;
    padding-bottom: 5rem;
  }
`;

const FooterBackground = styled.div`
  background-image: url(${DesktopbackgroundHome});
  ${SharedBackground};
  @media (max-width: 760px) {
    background-image: url(${MobileFooterBackground});
  }
`;
const JopProgressWrapper = styled.div`
  @media (max-width: 760px) {
    margin-top: 8rem;
  }
`;

const Home = props => {
  const {
    data: { datoCmsHomeLife }
  } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const advantagesOfGoget = [
    {
      img: (
        <StaticImage
          src={TrainedVerified}
          placeholder="blurred"
          loading="eager"
          alt="Trained & verified"
        />
      ),
      header: 'Trained & verified',
      desc: 'Hire experienced and skilled individuals.'
    },
    {
      img: (
        <StaticImage
          src={PerfectMatch}
          placeholder="blurred"
          loading="eager"
          alt="Perfect match"
        />
      ),
      header: 'Perfect match',
      desc: 'Customise your search and get the best person for your job.'
    },
    {
      img: (
        <StaticImage
          src={ConsistentlyReliable}
          placeholder="blurred"
          loading="eager"
          alt="Consistently reliable"
        />
      ),
      header: 'Consistently reliable',
      desc: 'Trust us to provide quality help every time.'
    },
    {
      img: (
        <StaticImage
          src={SafeSecure}
          placeholder="blurred"
          loading="eager"
          alt="Safe & secure"
        />
      ),
      header: 'Safe & secure',
      desc: 'Easily re-engage your trusted Favourite GoGetters.'
    }
  ];
  const homeConstant = {
    title: (
      <>
        Home, life and <br />
        on the go
      </>
    ),
    subTitle: 'We go beyond to help you complete all your tasks.',
    buttonText: 'Get Started'
  };
  return (
    <>
      <HeaderSeo
        title="GoGet | Find Reliable Part Timers for your Home and Daily Life"
        description="Get things done for your home and personal needs in your daily life with our reliable part timers."
        image={MetaImg}
        keywords="hire personal part timer, hire household helper, get personal assistant, hire gogetter"
        pathname={props.location.pathname}
        schemaMarkup={datoCmsHomeLife.schemaMarkup}
      />
      <Layout showHeaderButtons hideDownloadGoGetter isShowingFooterAppBar>
        <TopHeader
          backgroundImg={
            <StaticImage
              src={HelpAndLifeBanner}
              loading="eager"
              style={{ height: '100%' }}
              objectPosition="left top"
              placeholder="blurred"
              alt="Business Pricing"
            />
          }
          mobileBackgroundImg={
            <StaticImage
              src={MobileHelpAndLifeBanner}
              loading="eager"
              style={{ height: '100%' }}
              imgStyle={{ height: '100%' }}
              objectFit="cover"
              quality={100}
              placeholder="blurred"
              alt="Business Pricing"
            />
          }
          title={homeConstant.title}
          description={homeConstant.subTitle}
          btn1={{
            text: homeConstant.buttonText,
            link: process.env.GATSBY_PWA_PATH
          }}
          secondary
        />
        <AdvantagesOfGoget data={advantagesOfGoget} />

        <Wrapper>
          <HowToHelp />
        </Wrapper>
        <JopProgressWrapper>
          <JobsProgress />
        </JopProgressWrapper>

        <Container>
          <MeetOurCommunity data={meetCommunityData} />
        </Container>
        <HowItWorks marginExtra />

        <FooterBackground>
          <FAQSection data={FaqData} />
          <Banner
            backgroundColor={colors.primary}
            image={
              <StaticImage
                src={DesktopBannerImage}
                placeholder="blurred"
                style={{ height: '100%' }}
                alt="Banner image"
              />
            }
            mobileImage={
              <StaticImage
                src={MobileBannerImage}
                placeholder="blurred"
                alt="Banner image"
              />
            }
            title={'Did you know we can help grow your business too?'}
            subText={<> Scale your operations with skilled GoGetters!</>}
            btn1={{ text: 'LEARN MORE', link: '/business', type: 'secondry' }}
          />
          <DownloadUs
            appDownloadType="customer_app_download"
            pageSectionAnalytics="body"
          />
        </FooterBackground>
      </Layout>
    </>
  );
};

export default Home;

export const query = graphql`
  query HomeLifeQuery {
    datoCmsHomeLife {
      schemaMarkup
    }
  }
`;
