import React from 'react';
import Link from 'gatsby-link';
import styled from 'styled-components/macro';

import Avatar from '@material-ui/core/Avatar';

import GogetterAttire from '../../../assets/img/gogetter-dress-code.png';
import bike from '../../../assets/img/bike.png';
import personalHelper from '../../../assets/img/personalHelper.svg';
import car from '../../../assets/img/car.png';
import multipleLocation from '../../../assets/img/Can-i-have-deliveries-to-multiple-location.jpg';
import receiveItem from '../../../assets/img/What-if-im-not-there-to-receive-my-item.jpg';
import monitorGogetter from '../../../assets/img/monitorGogetter.png';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;
const StyledAvatar = styled(Avatar)`
  height: 60px !important;
  width: 60px !important;
`;
export const FaqData = [
  {
    question: 'How much should I pay GoGetters?',
    content: (
      <>
        <Wrapper>
          <StyledAvatar src={bike} alt="bike icon" />
          <div style={{ marginLeft: 8 }}>
            <div style={{ fontWeight: 900 }}>Motorbike</div>
            <div>Starts from RM5</div>
          </div>
        </Wrapper>
        <Wrapper>
          <StyledAvatar src={car} alt="bike icon" />
          <div style={{ marginLeft: 8 }}>
            <div style={{ fontWeight: 900 }}>Car</div>
            <div>Starts from RM7</div>
          </div>
        </Wrapper>
        <Wrapper>
          <StyledAvatar src={personalHelper} alt="bike icon" />
          <div style={{ marginLeft: 8 }}>
            <div style={{ fontWeight: 900 }}>General Helper Jobs</div>
            <div>Minimum RM10 per job</div>
          </div>
        </Wrapper>
        <br />
        <br />
        Pay your GoGetter only when the job is completed.
        <br />
        <br />
        If you are requesting for a ‘Food / Shopping’ job to buy groceries or
        food, you can use the GoGet Pay option which allows you to pay your
        GoGetter for the items on the GoGet app electronically when the job is
        completed.
        <br />
        <br />
        No upfront payments needed!
        <br />
        <br />
        <br />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Link to="/homelife/pricing">See full pricing list here</Link>
        </div>
      </>
    )
  },
  {
    question: 'What should I do if there is an issue with my job?',
    content: (
      <>
        We are here to provide you with priority support any time you need it.
        If any issues were to arise, kindly report the job to us and our
        dedicated team will reach out and help you out.
        <br />
        <br />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <a href="https://gogetmy.zendesk.com/hc/en-us/articles/212463768-How-can-I-report-someone-or-an-incident-">
            Also see: How to report my job
          </a>
        </div>
      </>
    )
  },
  {
    question: 'How can I get in touch with my GoGetter?',
    content: (
      <>
        <div style={{ color: '#00B4AD', fontWeight: 900 }}>i) GoGet Chat</div>
        Stay in touch with your GoGetter as they carry out your job.
        <br />
        <br />
        <div style={{ color: '#00B4AD', fontWeight: 900 }}>
          ii) Live Tracking
        </div>
        Track your GoGetter’s location in real time for the whole duration of
        your job.
        <br />
        <br />
        <div style={{ color: '#00B4AD', fontWeight: 900 }}>iii) Calls</div>
        Get in touch with your GoGetter via phone call once they have claimed
        your job.
      </>
    )
  },
  {
    question: "What if I'm not there to receive my item?",
    content: (
      <>
        Simply appoint a representative in your household or office who is able
        to accept the item on your behalf. Add your representative’s contact
        details in the 'Drop off contact' page.
        <br />
        <br />
        Additionally, you could also tick on the ‘Proof of delivery’ checkbox to
        receive further confirmation that your items have been delivered safely.
        <br />
        <br />
        <img alt="icon" src={receiveItem} />
        <br />
        <br />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <a href="https://gogetmy.zendesk.com/hc/en-us/articles/900000736726-What-happens-if-I-m-not-there-to-receive-my-item-can-they-pass-it-to-my-representative-">
            Learn more here
          </a>
        </div>
      </>
    )
  },
  {
    question: 'Can I choose who claims my job?',
    content: (
      <>
        <br />
        Yes, you can!
        <br />
        <br />
        Turn off the 'Auto Approve GoGetter' button which will ask you to first
        approve the GoGetter based on their ratings and reviews before they can
        do your job.
        <a
          style={{ marginLeft: 4 }}
          href="https://gogetmy.zendesk.com/hc/en-us/articles/360036609013-How-can-I-choose-my-GoGetter-"
        >
          Watch video tutorial here
        </a>
        <br />
        <br />
        <div>Alternatively, for more complex jobs, you can: </div>
        <div>i) Interview your GoGetters face to face</div>
        <div>ii) Conduct phone interviews</div>
        <div>iii) Request for your GoGetter’s resume</div>
        <br />
        <br />
        <img alt="icon" src={GogetterAttire} />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <a href={`${process.env.GATSBY_PWA_PATH}/personal_helper`}>
            Try it here
          </a>
        </div>
      </>
    )
  },
  {
    question: 'Can I have deliveries to multiple locations?',
    content: (
      <>
        Yes you can!
        <br />
        <br />
        For ‘Dispatch’ jobs, you can add up to 3 drop off locations! Simply
        click on the ‘+’ sign next to each location to add in a new address.
        <br />
        <br />
        <img alt="icon" src={multipleLocation} />
        <br />
        <br />
        However if you have more than 10 deliveries to complete in the same day,
        try the GoGet Bulk Upload feature which gives you flat rates!
        <br />
        <br />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Link to="/business/pricing">Learn about GoGet Bulk Upload</Link>
        </div>
      </>
    )
  },
  {
    question: 'Do I get a receipt after my jobs are done?',
    content: (
      <>
        Yes you do! Once a job is completed, a receipt is sent to your email
        automatically.
        <br />
        <br />
        Alternatively, you can also retrieve the receipt through your GoGet app
        or web browser.
        <br />
        <br />
        Simply
        <div>1. Please select the particular job from "My Jobs".</div>
        <div>2. Scroll to the bottom and click on "Receipt".</div>
      </>
    )
  },
  {
    question: 'Am I able to monitor my GoGetter? ',
    content: (
      <>
        Yes! You are able to track and monitor your GoGetter’s live location
        when he or she is on your job.
        <br />
        <br />
        For ‘Dispatch’ jobs, if you key in a drop off contact, your recipient
        will also receive an SMS from GoGet with a tracking link attached.
        <br />
        <br />
        <img src={monitorGogetter} alt="icon" />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <a href="https://gogetmy.zendesk.com/hc/en-us/articles/360008095873-Can-I-track-my-GoGetter-during-deliveries-">
            Learn more here
          </a>
        </div>
      </>
    )
  }
];
